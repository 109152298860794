import request from "@/utils/request";

/**
 * 获取oss参数
 * @param { String } extensions || 文件名
 */
export const getOssConfig = (data) => {
  return request({
    url: "/api/app/oss",
    method: "get",
    params:data
  });
};